import { Tag } from '@/src/types/HealthQuestionnaire';
import { TAilments, TJoints, TMarkets } from '@/src/types/Common';
import { MARKET } from '@consts/market';
import { acuteCondition } from '@components/healthQuestionnaire/helpers/conditions';
import { isJoint } from '@utils/joints';

let answers: Map<Tag, string | string[]> = new Map();
try {
  const storedAnswers = localStorage.getItem('HEALTH_ANSWERS');
  if (storedAnswers) {
    answers = new Map(JSON.parse(storedAnswers));
  }
} catch {
  console.warn('failed parsing answers');
}

export function getStoredAnswers() {
  return answers;
}

export function getSeekHelpFor(): TJoints | TAilments {
  return answers.get('SEEK_HELP_FOR') as TJoints | TAilments;
}

export function getAnswer(tag: Tag): string | string[] | undefined {
  return answers.get(tag);
}

export function storeAnswer(tag: Tag, optionId: string | string[]) {
  answers.set(tag, optionId);
  localStorage.setItem('HEALTH_ANSWERS', JSON.stringify(Array.from(answers.entries())));
}

export function clearAnswers() {
  answers.clear();
  localStorage.removeItem('HEALTH_ANSWERS');
}

const getMarketSpecificQuestion = (market: TMarkets) => {
  if (market === 'SE') {
    return {
      specific_joint_examined:
        getAnswer('SPECIFIC_JOINT_EXAMINED') === undefined
          ? true
          : getAnswer('SPECIFIC_JOINT_EXAMINED'),
    };
  }
  return {};
};

const getMarketSpecificBackQuestion = (market: TMarkets) => {
  if (market === 'SE') {
    return {
      low_back_three_weeks_pain: getAnswer('DURATION_OF_PAIN') === 'more_than_three_weeks',
    };
  }
  return undefined;
};

const getNonAcuteJointAnswers = (market: TMarkets) => {
  switch (getSeekHelpFor()) {
    case 'lower_back':
      return {
        frequency_of_low_back_pain: getAnswer('FREQUENCY_OF_LOW_BACK_PAIN'),
        when_low_back_pain_occurs: getAnswer('WHEN_LOW_BACK_PAIN_OCCURS'),
        low_back_pain_caused_by_accident: getAnswer('LOW_BACK_CAUSED_BY_ACCIDENT'),
        low_back_loss_sensation: getAnswer('LOW_BACK_LOSS_SENSATION'),
        have_had_cancer: getAnswer('LOW_BACK_HAVE_HAD_CANCER'),
        specific_joint_pain: getAnswer('SPECIFIC_LOW_BACK_PAIN'),
        low_back_radiating_pain: getAnswer('RADIATING_PAIN'),
        ...getMarketSpecificBackQuestion(market),
        ...getMarketSpecificQuestion(market),
      };
    case 'hip':
    case 'knee':
      return {
        specific_joint_oa_diagnosed: getAnswer('SPECIFIC_JOINT_OA_DIAGNOSED'),
        pain_under_pressure_or_movement: getAnswer('PAIN_UNDER_PRESSURE_OR_MOVEMENT'),
        stiffness_present: getAnswer('STIFFNESS_PRESENT'),
        stiffness_duration: getAnswer('STIFFNESS_DURATION'),
        works_standing_walking_rotating: getAnswer('WORKS_STANDING_WALKING_ROTATING'),
        sought_care_for_injured_specific_joint: getAnswer(
          'SOUGHT_CARE_FOR_INJURED_SPECIFIC_JOINT',
        ),
        specific_joint_pain: getAnswer('SPECIFIC_JOINT_PAIN'),
        ...getMarketSpecificQuestion(market),
      };
    case 'hand':
      return {
        specific_joint_oa_diagnosed: getAnswer('SPECIFIC_JOINT_OA_DIAGNOSED'),
        specific_joint_pain: getAnswer('SPECIFIC_HAND_JOINT_PAIN'),
        hand_knuckles_swollen: getAnswer('HAND_KNUCKLE_SWOLLEN'),
        hand_wrists_pain: getAnswer('HAND_WRISTS_PAIN'),
        hand_wrists_swollen: getAnswer('HAND_WRISTS_SWOLLEN'),
        ...getMarketSpecificQuestion(market),
      };
    case 'shoulder':
      return {
        frequency_of_shoulder_pain: getAnswer('FREQUENCY_OF_SHOULDER_PAIN'),
        when_shoulder_pain_occurs: getAnswer('WHEN_SHOULDER_PAIN_OCCURS'),
        shoulder_pain_caused_by_accident: getAnswer('SHOULDER_CAUSED_BY_ACCIDENT'),
        chest_pain: getAnswer('CHEST_PAIN'),
        have_had_cancer: getAnswer('SHOULDER_HAVE_HAD_CANCER'),
        specific_joint_pain: getAnswer('SPECIFIC_SHOULDER_JOINT_PAIN'),
        shoulder_radiating_pain: getAnswer('SHOULDER_RADIATING_PAIN'),
        shoulder_type_of_pain: getAnswer('PAIN_TYPE'),
        ...getMarketSpecificQuestion(market),
      };
    case 'neck':
      return {
        frequency_of_neck_pain: getAnswer('FREQUENCY_OF_PAIN'),
        when_neck_pain_occurs: getAnswer('WHEN_PAIN_OCCURS'),
        neck_related_pain: getAnswer('NECK_RELATED_PAIN'),
        neck_pain_caused_by_accident: getAnswer('PAIN_CAUSED_BY_ACCIDENT'),
        reduced_sensation: getAnswer('REDUCED_SENSATION'),
        reduced_strength: getAnswer('REDUCED_STRENGTH'),
        have_had_cancer: getAnswer('HAVE_HAD_CANCER'),
        specific_joint_pain: getAnswer('SPECIFIC_JOINT_PAIN'),
        neck_radiating_pain: getAnswer('RADIATING_PAIN'),
        radiating_pain_distance: getAnswer('RADIATING_DISTANCE'),
        ...getMarketSpecificQuestion(market),
      };
    default:
      return {};
  }
};

const getMostPainFulJointAnswer = () => {
  switch (getSeekHelpFor()) {
    case 'lower_back':
      return {
        most_painful_joint: 'lower_back',
      };
    case 'neck':
      return {
        most_painful_joint: 'neck',
      };
    case 'hand':
      return {
        most_painful_joint: getAnswer('MOST_PAINFUL_JOINT'),
        hand_specific_finger_joints: getAnswer('HAND_SPECIFIC_FINGER_JOINT'),
      };
    case 'hip':
    case 'knee':
    case 'lower_leg':
    case 'elbow':
    case 'foot':
    case 'shoulder':
    case 'wrist':
      return {
        most_painful_joint: getAnswer('MOST_PAINFUL_JOINT'),
      };
    case 'osteoporosis':
    case 'fall_prevention':
      return {
        most_painful_joint: null,
      };
    default:
      return {};
  }
};

const getAcuteJointAnswers = () => ({
  pain_duration: getAnswer('ACUTE_DURATION_OF_PAIN'),
  pain_frequency: getAnswer('ACUTE_FREQUENCY_OF_PAIN'),
  specific_joint_pain: getAnswer('ACUTE_SPECIFIC_JOINT_PAIN'),
  radiating_pain: getAnswer('ACUTE_RADIATING_PAIN_SCALE'),
  night_pain: getAnswer('ACUTE_NIGHT_PAIN'),
  pain_caused_by_accident: getAnswer('ACUTE_PAIN_CAUSED_BY_ACCIDENT'),
  chest_pain: getAnswer('ACUTE_CHEST_PAIN'),
  reduced_sensation: getAnswer('ACUTE_REDUCED_SENSATION'),
  reduced_sensation_abdomen: getAnswer('ACUTE_REDUCED_SENSATION_ABDOMEN'),
  have_had_cancer: getAnswer('ACUTE_HAVE_HAD_CANCER'),
  unexplained_weight_loss: getAnswer('ACUTE_WEIGHT_LOSS'),
  specific_joint_examined: getAnswer('ACUTE_SPECIFIC_JOINT_EXAMINED'),
  specific_joint_oa_diagnosed: getAnswer('SPECIFIC_JOINT_OA_DIAGNOSED'),
});

const getAilmentAnswers = () => {
  switch (getSeekHelpFor()) {
    case 'osteoporosis':
      return {
        osteoporosis_diagnosed: getAnswer('OSTEOPOROSIS_DIAGNOSED'),
        osteoporosis_osteopenia_diagnosed: getAnswer('OSTEOPOROSIS_OSTEOPENI_DIAGNOSED'),
        osteoporosis_diagnosed_during_fracture: getAnswer('OSTEOPOROSIS_FRACTURE'),
        osteoporosis_cause: getAnswer('OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL'),
        osteoporosis_exercise_recommended: getAnswer('OSTEOPOROSIS_EXERCISE'),
        osteoporosis_accident_or_fall_last_months: getAnswer('OSTEOPOROSIS_CAUSED_BY_ACCIDENT'),
        osteoporosis_sudden_back_pain: getAnswer('OSTEOPOROSIS_BACK_PAIN'),
        osteoporosis_have_or_had_cancer: getAnswer('OSTEOPOROSIS_HAVE_HAD_CANCER'),
        osteoporosis_sudden_weight_loss: getAnswer('OSTEOPOROSIS_WEIGHT_LOSS'),
      };
    case 'fall_prevention':
      return {
        fall_prevention_worried_about_falling: getAnswer('FALL_CONCERN'),
        fall_prevention_unsteady_standing_or_walking: getAnswer('FALL_FEEL'),
        fall_prevention_accident_or_fall_last_months: getAnswer('FALL_PAIN_CAUSED_BY_ACCIDENT'),
        fall_prevention_fallen_past_year: getAnswer('FALL_UNINTENTIONAL'),
        fall_prevention_times_fallen_past_year: getAnswer('FALL_RATE'),
        fall_prevention_symptoms_before_falling: getAnswer('FALL_SYMPTOMS'),
        fall_prevention_injured_by_fall: getAnswer('FALL_INJURY'),
        fall_prevention_assistive_device_advised: getAnswer('FALL_ASSISTIVE_DEVICE'),
        fall_prevention_using_assistive_device_during_fall: getAnswer(
          'FALL_TIME_ASSISTIVE_DEVICE',
        ),
        fall_prevention_medicine_side_effects: getAnswer('FALL_MEDICINE'),
        fall_prevention_have_or_had_cancer: getAnswer('FALL_HAVE_HAD_CANCER'),
        fall_prevention_sudden_weight_loss: getAnswer('FALL_WEIGHT_LOSS'),
      };
    default:
      return {};
  }
};

export function formatAnswers(
  market: TMarkets,
  omitEntryPoint = false,
): Record<string, string | number> {
  const entryPoint = omitEntryPoint ? undefined : 'web_clinic';
  const seekHelpFor = getSeekHelpFor();
  let specificAnswers;

  if (isJoint(seekHelpFor)) {
    // Entry point 'web_clinic' will always have acute questions
    specificAnswers = (acuteCondition() || entryPoint === 'web_clinic')
      ? getAcuteJointAnswers()
      : getNonAcuteJointAnswers(market);
  } else {
    specificAnswers = getAilmentAnswers();
  }

  const allAnswers = {
    ...getMostPainFulJointAnswer(),
    ...specificAnswers,
    ailment: isJoint(seekHelpFor) ? 'joint_pain' : seekHelpFor,
    entry_point: entryPoint,
    gender: getAnswer('GENDER'),
    height: getAnswer('HEIGHT'),
    height_unit: market === MARKET.US ? 'in' : 'cm',
    weight: getAnswer('WEIGHT'),
    weight_unit: market === MARKET.US ? 'lb' : 'kg',
  };
  return Object.entries(allAnswers).reduce((reducedAnswers, [key, value]) => {
    try {
      if (value) {
        const parsedValue = Array.isArray(value) ? value : JSON.parse(value);
        return { ...reducedAnswers, [key]: parsedValue };
      }
    } catch {
      // Do nothing
    }
    return { ...reducedAnswers, [key]: value };
  }, {});
}
